@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Nunito:wght@400;500;600&family=Poppins:wght@100;200;300;400;500;600&family=Roboto:wght@400;500;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
}
body{
    background-color: #292C35;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.midlink > .active > span{
  display: flex;
}


/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(63, 99, 207, 0.151); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0); 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.roboto{
    font-family: 'Roboto', sans-serif;
}
.nunito{
  font-family: 'Nunito', sans-serif;
}
.paintedblue{
    color: #718BFF;
}
.mymaron{
  color: #721b32;
}

.circle-big{
    width: 450px;
    height: 450px;
    border-radius: 50%;
    background: rgba(63, 99, 207, 0.04);
    transform: translateY(125px) translateX(-50px) ;
    z-index: -10;
}
.circle-small{
    background: rgba(63, 99, 207, 0.04);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    transform: translateX(200px) translateY(110px);
    z-index: -20;
}

.fullname{
    font-family: 'Abril Fatface', cursive;
}



.hello-container p{
  padding-top: 10px;
  padding-right: 2rem;
  font-weight: 400;
  opacity: 0;
}

.showtext{
  opacity: 0;
  animation-name: showtext;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  
}
.showtext2{
  opacity: 0;
  animation-name: showtext;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  
}
.midnav nav span:hover{
  color: #6ac3ff;
}


@keyframes showtext {
  from{
    transform: translateY(1rem);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}


.showline{
  animation: showline .5s linear;
}

@keyframes showline {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

/*Font poppins*/
.poppins{
  font-family: 'Poppins', sans-serif;
}
/*Font monospace*/
.monospace{
  font-family: monospace;
}
/*Font monospace*/
.applesys{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/*Font lucida*/
.helvetica{
  font-family: Helvetica, sans-serif;
}




.logo-line path{
  stroke-dasharray:3500;
  stroke-dashoffset:3500;
  animation: draw 3s ease-in forwards;

}
.logo-line2 path{
  stroke-dasharray:5500;
  stroke-dashoffset:-5500;
  animation: draw 3s ease-in forwards;

}
@keyframes draw{
  to{
      stroke-dashoffset: 0;
  }
}

.Hello{
  background: linear-gradient(89.82deg, #2FF3FF -20.09%, #4E6AE2 70.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
}
.academics, .techstacks , .projects{
  background: linear-gradient(89.82deg, #2FF3FF -20.09%, #4E6AE2 70.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.stickychange{
  background-color: #2FF3FF;
}



/*Section Four styles*/

.left-timeline{
  width: 100%;
}
.wrapper-timeline{
  width: 50%;
  padding: 0 2rem;
}
.wrapper-timeline:nth-child(even){
  align-self: flex-end;

}

.line-large{
  width: 8px;
  background: #718BFF;
  border-radius: 5px;
}
.line-large::after{
  content: '';
  position: absolute;
  top: 0;
  transform: translateX(-.5rem);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: inherit;
}

/* timelinemess */

.small-timeline .year-title::after{
  content: '';
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: calc(50% - 10px);
  left: -10px;
  transform: rotate(45deg);
}
.wrapper-timeline:nth-child(even) .left-title::after{
  content: '';
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  left: -10px;
  top: 2rem;
  transform: rotate(45deg);
}
.wrapper-timeline:nth-child(odd) .left-title::after{
  content: '';
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  right: -10px;
  top: 2rem;
  transform: rotate(45deg);
}


.yearone{
  animation: showyear 3s linear;
}
@keyframes showyear {
  from{
    transform: translateX(-200px);
  }
  to{
    transform: translateX(0px);
  }

}


.footsvg {
  position: relative;
  display: block;
  width: calc(100%);
  fill: #171717;
}
.booksvg{
  display: block;
  height: 30px;
  fill: transparent;
  width: calc(100%);
}


.booksvg .shape-fill {
  fill: #292C35;
}
.footsvg .shape-fill{
  fill: white;
}




/*xs*/
@media (max-width: 519px) { 
  .hello-container{
    min-width: 100vw;
    border-radius: 2rem;
    padding-left: 0;
  }
  .hello-image{
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hello-container h1{
    padding-bottom: 2rem;
  }
  .hello-container p{
    padding-right: 1.5rem;
    padding-left: 1rem;
    font-size: small;
  }
  .SectionTwo-title{
    justify-content: center;
    padding-top: 1rem;
  }
  .sectionOne{
    display: inline-block;
    text-align: center;
  }
  .fullname{
    transform: translateY(-15vh);
    width: 100vw;
    min-height: 40vh;
  }
  .fullname p{
    padding-left: 0;
    font-size: 9rem;
    text-align: center;
  }
  .fullname p:nth-child(4){
    margin-bottom: 0;
  }
  .paragraph1{
    text-indent: 0;
    font-size: 13px;
  }
  .logo-line{
    display: none;
  }
  .me-design{
    display: none;
  }
  .SectionOne{
    display: block;
  }

  

 }
 


 /* Login page custom css */

 .catglow{
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  width: 12rem;
  height: 12rem;
  border-radius: 100%;

 }

 .details-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 /* Dashboard custom grid layout */

 a.active {
  color: #718BFF;
 }
 .dashboard-container{
  background-color: #1B1D28;
  min-height: 100vh;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 250px 1fr;  /* side bar and main content */
  grid-template-rows: 70px 1fr;  /* header and main content */
}

.sidebar-left{
  background-color: #21262D;
  grid-column: 1;  /* side bar spans 1 column */
  grid-row: 1 / 3;  /* side bar spans 2 rows */
}

.dash-header{
  grid-column: 2 / 3;  /* header spans 2 columns */
  grid-row: 1;  /* header spans 1 row */
}

.main-content{
  background-color: #1B1D28;
  grid-column: 2;  /* main content spans 1 column */
  grid-row: 2;  /* main content spans 1 row */
}


/* choose file button */
::-webkit-file-upload-button {
  background: #718BFF;
  color: white;
  padding: 5px;
  border-radius: 4px;
  outline: none;
  border: none;
}
::-ms-browse {
  background: #718BFF;
  color: white;
  padding: 5px;
  border-radius: 4px;
  outline: none;
  border: none;
}